import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing READ operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const Tabs = makeShortcode("Tabs");
const TabPane = makeShortcode("TabPane");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-read-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-read-operation",
        "aria-label": "implementing read operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing READ operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-map-http-method"
        }}>{`1. Map HTTP method`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-handle-parameters"
        }}>{`2. Handle parameters`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-implement-service-code"
        }}>{`3. Implement service code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-return-response"
        }}>{`4. Return response`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-test-retrieving-a-single-post"
        }}>{`5. Test retrieving a single post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#6-test-retrieving-multiple-posts"
        }}>{`6. Test retrieving multiple posts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-step"
        }}>{`Next step`}</a></li>
    </ul>
    <p>{`In this step, we'll implement two methods at once. One is for retrieving a single post and another for multiple blog
posts. By completing this step, you'll learn to map your service with the HTTP GET (`}<a parentName="p" {...{
        "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
      }}>{`type://@Get`}</a>{`) method, use parameter injection (`}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{`), set default parameter value (`}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{`), and return a JSON object (`}<a parentName="p" {...{
        "href": "type://@ProducesJson:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesJson.html"
      }}>{`type://@ProducesJson`}</a>{`) as a response.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogPost.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-map-http-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-map-http-method",
        "aria-label": "1 map http method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Map HTTP method`}</h2>
    <p>{`Let's start `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#mapping-http-service-methods"
      }}>{`mapping the HTTP GET method`}</a>{` with our service method:`}</p>
    <Tabs mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`Map the HTTP GET method for retrieving a single post:`}</p>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`Declare a service method `}<inlineCode parentName="p">{`getBlogPost()`}</inlineCode>{` in the class `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Map this service method with the HTTP GET method by adding the `}<a parentName="p" {...{
                "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
              }}>{`type://@Get`}</a>{` annotation as follows.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Bind the endpoint `}<inlineCode parentName="p">{`/blogs`}</inlineCode>{` to the method.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java",
                "metastring": "filename=BlogService.java highlight=6",
                "filename": "BlogService.java",
                "highlight": "6"
              }}>{`import com.linecorp.armeria.server.annotation.Get;

public final class BlogService {
  ...

  @Get("/blogs")
  public void getBlogPost(int id) {
    // Retrieve a single post
  }
}
`}</code></pre>
          </li>
        </ol>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <p>{`Map the HTTP GET method for retrieving multiple posts:`}</p>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`Declare a service method `}<inlineCode parentName="p">{`getBlogPosts()`}</inlineCode>{` in the class `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Map this service method with the HTTP GET method by adding the `}<a parentName="p" {...{
                "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
              }}>{`type://@Get`}</a>{` annotation as follows.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Bind the endpoint `}<inlineCode parentName="p">{`/blogs`}</inlineCode>{` to the method.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java",
                "metastring": "filename=BlogService.java highlight=6",
                "filename": "BlogService.java",
                "highlight": "6"
              }}>{`import com.linecorp.armeria.server.annotation.Get;

public final class BlogService {
  ...

  @Get("/blogs")
  public void getBlogPosts(boolean descending) {
    // Retrieve multiple posts
  }
}
`}</code></pre>
          </li>
        </ol>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "2-handle-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-handle-parameters",
        "aria-label": "2 handle parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Handle parameters`}</h2>
    <p>{`Take in information through `}<em parentName="p">{`path`}</em>{` and `}<em parentName="p">{`query`}</em>{` parameters for retrieving blog posts. For retrieving a single post, we'll take a blog post ID as the path parameter. For multiple posts, we'll take the sorting order as a query parameter.`}</p>
    <Tabs defaultActiveKey="1" mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`Let's handle parameters for retrieving a single post:`}</p>
        <ol>
          <li parentName="ol">{`To take in a path parameter, add `}<inlineCode parentName="li">{`/:id`}</inlineCode>{` to the `}<a parentName="li" {...{
              "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
            }}>{`type://@Get`}</a>{` annotation's parameter as in line 6.`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/docs/server-annotated-service#parameter-injection"
            }}>{`Inject the path parameter`}</a>{` to the service method, annotate the parameter with `}<a parentName="li" {...{
              "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
            }}>{`type://@Param`}</a>{` as in line 7.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java showlineno=true",
            "filename": "BlogService.java",
            "showlineno": "true"
          }}>{`import com.linecorp.armeria.server.annotation.Param;

public final class BlogService {
 ...

 @Get("/blogs/:id")
 public void getBlogPost(@Param int id) {
   // Retrieve a single post
 }
}
`}</code></pre>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <p>{`Let's handle parameters for retrieving multiple posts:`}</p>
        <ol>
          <li parentName="ol">
            <p parentName="li">{`Specify the endpoint for the service using the `}<a parentName="p" {...{
                "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
              }}>{`type://@Get`}</a>{` annotation.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><a parentName="p" {...{
                "href": "/docs/server-annotated-service#parameter-injection"
              }}>{`Inject the parameter`}</a>{` by annotating the parameter `}<inlineCode parentName="p">{`descending`}</inlineCode>{` with `}<a parentName="p" {...{
                "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
              }}>{`type://@Param`}</a>{` as in line 8.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Set the default sorting order to descending by annotating the parameter `}<inlineCode parentName="p">{`descending`}</inlineCode>{` with `}<a parentName="p" {...{
                "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
              }}>{`type://@Default`}</a>{`, with its parameter as `}<inlineCode parentName="p">{`"true"`}</inlineCode>{`.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java",
                "metastring": "filename=BlogService.java showlineno=true",
                "filename": "BlogService.java",
                "showlineno": "true"
              }}>{`import com.linecorp.armeria.server.annotation.Param;
import com.linecorp.armeria.server.annotation.Default;

public final class BlogService {
  ...

  @Get("/blogs")
  public void getBlogPosts(@Param @Default("true") boolean descending) {
    // Retrieve multiple posts
  }
}
`}</code></pre>
          </li>
        </ol>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "3-implement-service-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-implement-service-code",
        "aria-label": "3 implement service code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Implement service code`}</h2>
    <p>{`In this step, write the code required for service itself.`}</p>
    <Tabs defaultActiveKey="1" mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`To retrieve a single blog post information, copy the following code inside the `}<inlineCode parentName="p">{`getBlogPost()`}</inlineCode>{` method.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=3",
            "filename": "BlogService.java",
            "highlight": "3"
          }}>{`@Get("/blogs")
public void getBlogPost(@Param int id) {
  BlogPost blogPost = blogPosts.get(id);
}
`}</code></pre>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <p>{`To retrieve multiple blog posts, copy the following code inside the `}<inlineCode parentName="p">{`getBlogPosts()`}</inlineCode>{` method. Note that the return type has been changed from `}<inlineCode parentName="p">{`void`}</inlineCode>{` to `}<inlineCode parentName="p">{`Iterable<BlogPost>`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java",
            "filename": "BlogService.java"
          }}>{`import java.util.Map.Entry;
import java.util.Collections;
import java.util.Comparator;
import java.util.stream.Collectors;

@Get("/blogs")
public Iterable<BlogPost> getBlogPosts(@Param @Default("true") boolean descending) {
  // Descending
  if (descending) {
      return blogPosts.entrySet()
                      .stream()
                      .sorted(Collections.reverseOrder(Comparator.comparingInt(Entry::getKey)))
                      .map(Entry::getValue).collect(Collectors.toList());
  }
  // Ascending
  return blogPosts.values().stream().collect(Collectors.toList());
}
`}</code></pre>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "4-return-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-return-response",
        "aria-label": "4 return response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Return response`}</h2>
    <p>{`Let's return a response for the service call.`}</p>
    <Tabs defaultActiveKey="1" mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`To return a response for getting a single post:`}</p>
        <ol>
          <li parentName="ol">{`Replace the return type of the `}<inlineCode parentName="li">{`getBlogPost()`}</inlineCode>{` method from `}<inlineCode parentName="li">{`void`}</inlineCode>{` to `}<a parentName="li" {...{
              "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
            }}>{`type://HttpResponse`}</a>{`.`}</li>
          <li parentName="ol">{`Return a response using Armeria's `}<a parentName="li" {...{
              "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
            }}>{`type://HttpResponse`}</a>{` containing the content of the blog post retrieved.`}</li>
        </ol>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=5,8",
            "filename": "BlogService.java",
            "highlight": "5,8"
          }}>{`import com.linecorp.armeria.common.HttpResponse;

public final class BlogService {
  @Get("/blogs/:id")
  public HttpResponse getBlogPost(@Param int id) {
    ...

    return HttpResponse.ofJson(blogPost);
  }
}
`}</code></pre>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <p>{`We've already implemented returning multiple blog posts in the previous step. Here, annotate the method `}<inlineCode parentName="p">{`getBlogPosts()`}</inlineCode>{` with `}<a parentName="p" {...{
            "href": "type://@ProducesJson:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesJson.html"
          }}>{`type://@ProducesJson`}</a>{`. This converts a list of `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` objects into a JSON response.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=4",
            "filename": "BlogService.java",
            "highlight": "4"
          }}>{`import com.linecorp.armeria.server.annotation.ProducesJson;

@Get("/blogs")
@ProducesJson
public Iterable<BlogPost> getBlogPosts(@Param @Default("true") boolean descending) {
  // Retrieve multiple blog posts
}
`}</code></pre>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "5-test-retrieving-a-single-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-test-retrieving-a-single-post",
        "aria-label": "5 test retrieving a single post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Test retrieving a single post`}</h2>
    <p>{`Let's test if we can retrieve a blog post we created.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a test method to retrieve the first blog post with ID `}<inlineCode parentName="p">{`0`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@Test
void getBlogPost() throws JsonProcessingException {
  final WebClient client = WebClient.of(server.httpUri());
  final AggregatedHttpResponse res = client.get("/blogs/0").aggregate().join();
  final Map<String, Object> expected = Map.of("id", 0,
              "title", "My first blog",
              "content", "Hello Armeria!");

  assertThatJson(res.contentUtf8()).whenIgnoringPaths("createdAt", "modifiedAt")
              .isEqualTo(mapper.writeValueAsString(expected));
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add annotations to configure the order our test methods will be executed.
The annotations guarantee that the first blog post will be created in the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method before we try to retrieve it in the `}<inlineCode parentName="p">{`getBlogPost()`}</inlineCode>{` method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import org.junit.jupiter.api.MethodOrderer.OrderAnnotation;
import org.junit.jupiter.api.Order;
import org.junit.jupiter.api.TestMethodOrder;

@TestMethodOrder(OrderAnnotation.class) // Add this
class BlogServiceTest {
  ...

  @Test
  @Order(1) // Add this
  void createBlogPost() throws JsonProcessingException {
    ...
  }

  @Test
  @Order(2) // Add this
  void getBlogPost() throws JsonProcessingException {
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run all the test cases on your IDE or using Gradle.`}</p>
        <p parentName="li">{`Your client retrieved a blog post from the server successfully if the test is passed.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "6-test-retrieving-multiple-posts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-test-retrieving-multiple-posts",
        "aria-label": "6 test retrieving multiple posts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Test retrieving multiple posts`}</h2>
    <p>{`Finally, let's test if we can retrieve multiple posts.
Add a test method like the following to create the second blog post and test retrieving the list of blog posts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import java.util.List;

@Test
@Order(3)
void getBlogPosts() throws JsonProcessingException {
  final WebClient client = WebClient.of(server.httpUri());
  final HttpRequest request = createBlogPostRequest(Map.of("title", "My second blog",
                "content", "Armeria is awesome!"));
  client.execute(request).aggregate().join();
  final AggregatedHttpResponse res = client.get("/blogs").aggregate().join();
  final List<Map<String, Object>> expected = List.of(
          Map.of("id", 1,
                 "title", "My second blog",
                 "content", "Armeria is awesome!"),
          Map.of("id", 0,
                 "title", "My first blog",
                 "content", "Hello Armeria!"));
  assertThatJson(res.contentUtf8()).whenIgnoringPaths("[*].createdAt", "[*].modifiedAt")
                .isEqualTo(mapper.writeValueAsString(expected));
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <p>{`You can test this also with Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`. See `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/add-services-to-server#using-docservice-after-adding-service-methods"
      }}>{`Using DocService after adding service methods`}</a>{` for instructions.`}</p>
    <h2 {...{
      "id": "next-step",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-step",
        "aria-label": "next step permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next step`}</h2>
    <p>{`In this step, we've implemented methods for a READ operation and used Armeria's annotations; `}<a parentName="p" {...{
        "href": "type://@Get:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Get.html"
      }}>{`type://@Get`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@ProducesJson:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ProducesJson.html"
      }}>{`type://@ProducesJson`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{`, and `}<a parentName="p" {...{
        "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
      }}>{`type://@Default`}</a>{`.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/implement-update"
      }}>{`Step 6. Implement UPDATE`}</a>{`, we'll implement an UPDATE operation to modify existing blog posts.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      